import { defineStore } from "pinia";
interface User{
    chartletLevelType: Number,
    level: Number,
    levelType: Number,
    chartletLevelNewType: Number,
    chartletLevel: Number,
    levelNewType: Number,
    headImgUrl: string,
    nickname: string,
    mailAddr: string
}

const useLoginStore = defineStore("login", {
  state: () => ({
    userInfo: {} as User,
  }),
  getters: {
    loginStatus: (state) => !!Object.keys(state.userInfo).length,
    userLevelType: ({ userInfo: ui }) => ui.chartletLevelType,
    isFree: ({ userInfo: ui }) =>
      ui.level === 0 && ui.levelType === 0 && !ui.chartletLevelNewType,
    // 是否个人 数字人pro版 (包含月度和年度)
    isPro: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 0 &&
          (ui.chartletLevelNewType === 13 || ui.chartletLevelNewType === 14)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 13 || ui.levelNewType === 14),

    // 是否企业 数字人pro+版 (包含月度和年度)
    isProPlus: ({ userInfo: ui }) =>
      ui.chartletLevelNewType
        ? ui.chartletLevelType === 2 &&
          (ui.chartletLevelNewType === 15 || ui.chartletLevelNewType === 16)
        : ui.chartletLevel === 0 &&
          (ui.levelNewType === 15 || ui.levelNewType === 16),
  },
  actions: {
    changeLogin(data: any) {
      this.userInfo = Object.keys(data).length ? data : {};
    }
  },
});
export default useLoginStore;
