export function openWin(url: string){
  const tagA = document.createElement("a");
  tagA.href = url;
  tagA.target = "_blank";
  tagA.click(); // 点击事件
  tagA.remove();
};

export function handleAssetsUrl(url: string){
  if (!url) return;
  const httpReg = /^http/;
  const reg = /^\//;
  const slashHttpReg = /^\/http/;
  const qiniuAssetsUrl = "https://fresource.laihua.com";
  if (httpReg.test(url) || url.indexOf("base64") != -1) {
      // 兼容第三方登录的头像地址为http的情况，替换为https
      return url.replace(/http:/, "https:");
  }
  // 兼容以/开头的绝对路径：/http://xxxxx.com
  if (slashHttpReg.test(url)) {
      return url.substr(1);
  } else if (reg.test(url)) {
      return qiniuAssetsUrl + url;
  } else {
      return qiniuAssetsUrl + "/" + url;
  }
};