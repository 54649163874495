import { createApp } from 'vue'
import './style.css'
import App from './App.vue'
import router from './router/index'
import { createHead } from '@unhead/vue'
import { pinia } from "@/store";

const app = createApp(App)
const head = createHead()

app.use(pinia).use(router).use(head).mount("#app");
